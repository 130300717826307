import React, {useContext, useMemo, useState} from "react";

import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import {LightContainer, MyModal} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {UserContext} from "../user/UserContext";
import "./style.sass"
import EventEdit from "./eventEdit";
import {dateFormat} from "../core/dateFuncs";
import OfferList from "./offerList";
import {Redirect, useHistory} from "react-router";
import {EventRegistrationList} from "./eventRegistrations";
import PersonList from "../user/personList";
import {EventKindMap} from "../core/enums";


export default function EventView({match}) {
    const event_ID = match?.params?.event_ID;
    const addNew = event_ID === "neu";
    const [event, setEvent] = useState(addNew ? {} : null);
    const [offers, setOffers] = useState([]);
    const [permitted, setPermitted] = useState(null);
    const [exams, setExams] = useState(null);
    const [{error, loading, success}, setStatusVar] = useState({loading: !addNew});
    const user = useContext(UserContext)?.user || {}
    const loadEvent = () => {
        maxiGet(`/event/${event_ID}`, {setStatusVar}).then(({event, offers, exams, permitted}) => {
            setOffers(offers)
            setPermitted(permitted)
            setExams(exams)
            setEvent({
                ...event,
                eventStart: new Date(event.eventStart),
                eventEnd: new Date(event.eventEnd),
                bookingStart: new Date(event.bookingStart),
                bookingEnd: new Date(event.bookingEnd),
            })
        })
    }
    useMemo(() => {
        !addNew && loadEvent()
    }, [])
    return <Container name={"Veranstaltung "} addName={event?.name}>

        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        {
            event && !addNew && <EventPrint event={event} exams={exams} user={user} permitted={permitted}/>
        }


        {
            (user.role === 100 || permitted || addNew) && event && <>
                {event && !addNew && <EventRegistrationList event={event}/>}
                {!addNew && <OfferList {...{reload: loadEvent, event_ID, event, offers}}/>}
                <EventEdit {...{reload: loadEvent, event}}/>
            </>
        }

    </Container>
}
const now = new Date();

function EventPrint({event, exams, user, permitted}) {
    const history = useHistory();

    const [registrationID, setRegistrationID] = useState(null);
    const [{error, loading}, setStatusVar] = useState({});
    const {city, eventStart, eventEnd, description} = event
    const addNewRegistration = () => maxiPost("/registration/add", {event_ID: event.ID}, {setStatusVar}).then(resp => {
        setRegistrationID(resp.registration_ID);
    }).catch(e => {
        if (e === "nicht eingeloggt") {

            localStorage.setItem("redir", "/veranstaltungen/" + event.ID);
            window.location.href = "/benutzer/login"
        }
    })

    if (registrationID) {
        return <Redirect to={"/anmeldungen/" + registrationID}/>
    }
    return <div>
        <b>{city}, {eventStart && dateFormat(eventStart)} bis {eventEnd && dateFormat(eventEnd)}{now < event.bookingEnd && <>, Anmeldung bis {dateFormat(event.bookingEnd)}</>}</b>

        <div dangerouslySetInnerHTML={{__html: description}}/>
        <Status type={"error"} text={error}/>

        {
            event.bookingStart < now && now < event.bookingEnd && (!user?.permission || user.permission >= event.minPermission) && (!event.isRegistered || permitted) &&
            <><MaxBtn onClick={addNewRegistration}>Anmelden</MaxBtn> &nbsp;</>
        }
        {
            event.isRegistered && <b>Du bist bereits angemeldet.</b>
        }
        {
            event.examRequirement && !event.examRequirementMet && <p>
                Bitte absolviere <MaxLink to={`/prüfungsantritt/${event.examRequirement}`}>{event.examRequirementName}</MaxLink> für die Teilnahme an dieser Veranstaltung.
            </p>
        }
        &nbsp;
        &nbsp;
        &nbsp;
        <UserContext>
            {
                context => <>
                    {
                        (context.user?.role > 80 || permitted) && <>
                            <AddRegistrationForPerson event={event} setRegistrationID={setRegistrationID}/>
                            &nbsp;        &nbsp;
                            {
                                /*event.examIncluded && <>
                                    <MaxBtn onClick={() => window.confirm("Prüfung nun starten? Bitte überprüfe, dass alle Teilnehmer im Startbildschirm sind und daher für diese Prüfung angemeldet haben.") &&
                                        maxiPost(`/exam/${event.examIncluded}/examstart_for_all`, {event_ID: event.ID}, {setStatusVar}).then(examstart => history.push(`/prüfungsantritt/${examstart.ID}/0`))}>
                                        Prüfung starten
                                    </MaxBtn>&nbsp;
                                </>*/
                            }
                        </>
                    }
                    <Loader loading={loading}/>
                    {
                        (event.isRegistered || permitted) && (event.kind.value === EventKindMap.pruefung || event.kind.value === EventKindMap.onlinepruefung) && <>

                            {
                                exams
                                ?.sort((a,b)=>a.ID<b.ID?-1:1)
                                .map(exam => <div style={{padding: 10, backgroundColor: "var(--maincolorlight)", borderRadius: 8, marginBottom: 10}}>
                                    <h3>{exam.name}</h3>
                                    {
                                        permitted ? <>
                                                <MaxBtn onClick={() => !loading && window.confirm("Prüfung nun starten? Bitte überprüfe, dass alle Teilnehmer im Startbildschirm sind und daher für diese Prüfung angemeldet haben.") &&
                                                    maxiPost(`/exam/${exam.ID}/examstart_for_all`, {event_ID: event.ID}, {setStatusVar}).then(examstart => history.push(`/prüfungsantritt/${examstart.ID}/0`))}>
                                                    Prüfung starten
                                                </MaxBtn>&nbsp;
                                                <MaxBtn onClick={() => maxiPost(`/exam/${exam.ID}/examstart_for_all?only_results=1`, {event_ID: event.ID}, {setStatusVar}).then(examstart => history.push(`/prüfungsantritt/${examstart.ID}/resultatAlle`))}>
                                                    Prüfungs-Ergebnisse
                                                </MaxBtn>&nbsp;

                                            </> :
                                            <>

                                                Voraussetzungen für Teilnahme <b>{exam.requirementsMet ? "erfüllt" : "nicht erfüllt"}</b><br/>

                                                {
                                                    exam.requirementsMet && <MaxLink to={`/prüfungsantritt/${exam?.ID}`}>An Prüfung teilnehmen</MaxLink>
                                                }
                                            </>
                                    }
                                </div>)
                            }
                        </>
                    }
                </>
            }
        </UserContext>
    </div>
}


function AddRegistrationForPerson({event, setRegistrationID}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [persons, setPersons] = useState([]);

    const context = useContext(UserContext);
    useMemo(() => context.user?.role < 38 && maxiGet("/person").then(({persons}) => setPersons(persons)), [context.user?.role])

    const handleSubmit = (person) => {
        !loading && maxiPost(`/registration/add_superuser`, {event_ID: event.ID, person_ID: person.ID}, {setStatusVar})
            .then((resp) => {
                setRegistrationID(resp.registration_ID)
            })
    }
    return <MyModal trigger={<MaxBtn>Registrierung für Person hinzufügen</MaxBtn>}>
        <LightContainer name={"Person hinzufügen"}>
            <Status type={"error"} text={error}/>
            <PersonList
                persons={persons}
                error={""}
                setPersons={setPersons}
                nameCell={({original, value}) => <em onClick={() => {
                    handleSubmit(original)
                }}>{value} (hinzufügen)</em>}
                loadPersons={() => null}
                match={{}}
                loading={false}
            />
        </LightContainer>
    </MyModal>
}
