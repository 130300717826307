import React, {useContext, useState} from "react";
import axios from "axios/index";
import Consts from "./consts";
import ReactSelect, {components} from 'react-select';
import {FormContext} from "./form_context";
import {dateFormatTime} from "./dateFuncs";
import DatePicker, {registerLocale} from "react-datepicker";
import {Redirect} from "react-router";
import de from 'date-fns/locale/de';

import CreatableSelect from 'react-select/creatable';
import {apiGet, apiPost} from "./api";
import {FaAngleDown, FaAngleRight, FaTimesCircle, FaTrashAlt, FaCheck, FaCopy} from "react-icons/fa";
import Scrollspy from 'react-scrollspy'
import ReactTable from "react-table";
import Dialog from "@material-ui/core/Dialog";
import Popup from "reactjs-popup";
import {Editor} from "@tinymce/tinymce-react";
import Status from "./status";
import {UserContext} from "../user/UserContext";
import {CopyToClipboard} from "react-copy-to-clipboard";
import 'react-table/react-table.css';
import "react-datepicker/dist/react-datepicker.css";
import "../core/modal.sass"
/*import "quill/dist/quill.snow.css";
import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize';
import ImageUploader from "quill-image-uploader"
import {ImageDrop} from 'quill-image-drop-module';
import QuillBetterTable from 'quill-better-table'

Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register({
    'modules/better-table': QuillBetterTable
}, true)*/

registerLocale('de', de);

class SelectfieldInput extends React.Component {
    static contextType = FormContext;
    state = {
        selectedOption: null,
    };


    localHandleChange = (selectedOption) => {
        this.setState({selectedOption});
        const t = this.props.tag.split("_");
        if (t.length === 2) {
            if (!!this.props.multiple) {
                const n = this.context.state[t[0]][t[1]].concat(selectedOption.map(s => s.value));
                this.context.updateState(t[0], t[1], n)
            } else {
                this.context.updateState(t[0], t[1], selectedOption.value)
            }
        } else {
            console.log("not handled")
        }

        //this.context.updateState("remind", "daysBefore", selectedOption.map(a => a.value))
        //this.context.updateState("remind", "daysBeforeSelect", selectedOption)

    };

    constructor(props) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.myRef = React.createRef();
    };

    componentDidMount() {
        //console.log("mount of " + this.props.tag, this.props)
    }

    componentDidUpdate() {
        const t = this.props.tag.split("_");
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (t.length === 2) {
            if (state[t[0]] !== undefined && state[t[0]][t[1]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]][t[1]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + this.props.tag, " having state", stateValue, " and value", domValue)

                // comparison has to be type ignorant otherwise update triggers when only the type changes from sqlalchemy to javascript
                if (stateValue != domValue && this.context.updateState !== undefined) {
                    this.context.updateState(t[0], t[1], domValue)
                }
            }
        }
        if (t.length === 1) {
            if (state[t[0]] !== undefined && this.myRef.current !== null) {
                const stateValue = state[t[0]];
                const domValue = this.myRef.current.value;
                //console.log("update of " + this.props.tag, " having state", stateValue, " and value", domValue)

                // comparison has to be type ignorant otherwise update triggers when only the type changes from sqlalchemy to javascript
                if (stateValue != domValue && this.props.updateState !== undefined) {
                    this.context.updateState(t[0], "", domValue)
                }
            }
        }

    }

    render() {

        const s = this.props.tag.split("_");
        let dv = "";
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }

        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }

        let object_select = null;
        let defaultLabel;
        if (this.props.type !== "reactselect") {
            if (Array.isArray(this.props.selectives[0])) {
                object_select = this.props.selectives.filter(a => a !== null).map(a => <option key={a[0]}
                                                                                               value={a[0]}>{a[1]}</option>)
            } else {
                object_select = this.props.selectives.filter(a => a !== null).map(a => {
                    a = a.toString();
                    return <option key={a} value={a}>{a.split("|")[0]}</option>
                })
            }
        } else {

            let defaultLabelArray = this.props.selectives.filter(f => f.value === dv);
            if (defaultLabelArray.length > 0) {
                defaultLabel = defaultLabelArray[0].label
            }
            if (dv !== undefined && dv !== null && dv.length === 0) {
                dv = null;
                defaultLabel = null
            }
        }

        //const selectedOption = null//this.props.type !== "reactselect" ? null : state.remind.daysBeforeSelect;
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
            }),
        };

        return <>
            {(this.props.selectives !== undefined && this.props.type === "reactselect") ?
                <div style={(!this.props.inline ? {} : {display: "inline-block"})}>
                    {
                        !this.props.noLabel && <label style={{}}>
                        <span>
                            {this.props.name}
                        </span>
                        </label>
                    }
                    <div style={{width: this.props.width || "300px", display: "inline-block"}}>
                        <UserContext.Consumer>
                            {
                                ({club}) =>
                                    <ReactSelect
                                        key={this.props.tag}
                                        name={this.props.tag}
                                        style={{width: this.props.width}}
                                        placeholder={this.props.ph || "Auswählen"}
                                        styles={customStyles}
                                        value={!!dv ? {label: defaultLabel || dv, value: dv} : null}
                                        //defaultValue={{label: defaultLabel || dv, value: dv}}
                                        isMulti={!!this.props.multiple}
                                        className={"reactSelectContainer"}
                                        menuPortalTarget={document.getElementById("reactselectportal")}
                                        onChange={(e) => this.props.readOnly ? alert("Eine Veränderung ist nicht erlaubt") : this.localHandleChange(e)}
                                        options={this.props.selectives}
                                        classNamePrefix={"MaxSelect"}
                                        noOptionsMessage={this.props.noOptionsMessage}
                                        onMenuOpen={this.props.onMenuOpen}
                                        defaultMenuIsOpen={this.props.defaultOpen}
                                        components={{}}
                                        isDisabled={this.props.readOnly}
                                        theme={(theme) => {
                                            return {
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: club?.color,
                                                    primary25: club?.color,
                                                    neutral20: 'black',
                                                    neutral30: club?.color,
                                                },
                                            }
                                        }}
                                    />
                            }
                        </UserContext.Consumer>
                    </div>
                </div> :

                <label style={this.props.type === "hidden" ? {display: "none"} : {display: "inline-block"}}>
                    {
                        !this.props.noLabel &&
                        <span>
                        {this.props.name}
                     </span>
                    }
                    {this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives === undefined &&
                        <span>nichts auszuwählen</span>
                    }
                    {this.props.selectives !== undefined && this.props.selectives.length === 0 && Array.isArray(this.props.selectives) && this.props.noselectives !== undefined &&
                        this.props.noselectives
                    }
                    {/*this.props.selectives !== undefined && this.props.selectives.length > 0 && this.props.type === "select2" &&
                <Select2
                    data={this.props.selectives.map(a => {
                        return {id: a.id, "text": a.text.substr(0, 40)}
                    })}
                    value={dv}
                    onChange={(e) => {
                        if (this.props.tag.split("_").length == 2) {
                            this.props.updateState(this.props.tag.split("_")[0], this.props.tag.split("_")[1], e.target.value)
                        }
                    }
                    }
                    options={{placeholder: 'search for ' + this.props.name,}}
                />*/
                    }

                    {this.props.selectives !== undefined && (this.props.selectives.length > 0 || !Array.isArray(this.props.selectives)) && this.props.type !== "select2" && this.props.type !== "reactselect" &&
                        <select
                            key={this.props.tag}
                            name={this.props.tag}
                            style={{width: this.props.width}}
                            ref={this.myRef}
                            //multiple
                            onChange={() => {
                            }}
                            value={dv}
                            //className="js-example-basic-single"
                        >
                            {
                                this.props.demandSelect &&
                                <option value="">bitte auswählen</option>
                            }

                            {
                                object_select
                            }
                        </select>
                    }
                    &nbsp;{this.props.children}
                    {this.props.noBreaks === undefined && <br/>}
                </label>
            }
        </>
    }
}

class FileUpload extends React.Component {
    static contextType = FormContext;
    saveFile = () => {


        this.setState({loading: true});

        var formData = new FormData();
        formData.append("file", this.fileField.current.files[0]);
        axios.post(Consts.API_PREFIX + "/licence/file/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.setState({
                    progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                })
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({loading: false, error: response.data.message})
                } else {
                    const fieldNameSplit = this.fieldName.split("_");
                    this.updateState(fieldNameSplit[0], fieldNameSplit[1], response.data.fileToken)
                    this.setState({loading: false, error: "", renew: false})
                }
            })
            .catch((error) => {
                console.log("error", error.toString());
                this.setState({loading: false, error: error.toString()})
            });
    };

    constructor(props, context) {
        super(props);
        this.updateState = props.updateState || context.updateState;
        this.fieldName = props.tag === undefined ? "fileID" : props.tag;
        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 100,

        };
        this.setState = this.setState.bind(this);
    }

    /* loadBox(){
         let folderId = '59771699940';
         var accessToken = 'abc';
         var contentExplorer = new Box.ContentExplorer();
         contentExplorer.show(folderId, "qzCTquq9kJNCFYYj8k4fhUppIgCZ0quQ", {
             container: '.container'
         });
     }*/
    render() {
        const MyLabel = () => <span style={{...(this.props.width ? {width: this.props.width + "px"} : {})}}>
                            {this.props.name}
                        </span>;
        const s = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;

        const fieldNameSplit = this.fieldName.split("_");

        return <div>
            <Status type={"error"} text={this.state.error}/>
            {
                !s.loading && !(s[fieldNameSplit[0]] || {})[fieldNameSplit[1]] &&
                <div id="excel_file">
                    <label>
                        <MyLabel/>
                        <input
                            type={"file"}
                            ref={this.fileField}
                        />
                        {this.state.loading &&
                            <label>
                                <img alt={"loader"} width={20} height={20}
                                     src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> {this.state.progress} % {this.state.progress === 100 &&
                                <span>(verarbeite Datei)</span>}
                            </label>
                        }
                    </label>
                    <button type={"button"} className={"maxbtn"} onClick={this.saveFile}>Datei hochladen</button>
                </div>
            }
            {
                !!s[fieldNameSplit[0]][fieldNameSplit[1]] &&
                <label>
                    <MyLabel/> <FaCheck/> &nbsp;&nbsp;&nbsp;&nbsp;<a
                    href={Consts.API_PREFIX + "/licence/file/download/" + s[fieldNameSplit[0]][fieldNameSplit[1]]}>Datei anzeigen<PrintFileMetadata fileToken={s[fieldNameSplit[0]][fieldNameSplit[1]]}/></a> oder <em onClick={() => {
                    this.updateState(fieldNameSplit[0], fieldNameSplit[1], "");
                }}>andere Datei auswählen</em><br/>
                </label>
            }


            {s.loading !== undefined && s.loading &&
                <img alt={"loader"} width={20} height={20} src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/>
            }
        </div>
    }
}


function PrintFileMetadata({fileToken: input}) {
    const fileToken = input.split("/")[1]
    return null
    return <>
        (
        {fileToken.split(".")[1]}, {dateFormatTime(new Date(fileToken.split("-")[0] * 1000))}
        )
    </>
}

class TextfieldInput extends React.Component {
    constructor(props) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.localRef = React.createRef();
    };

    static contextType = FormContext;

    componentDidMount() {
        if (this.props.events === undefined) {
            return
        }
        for (var key in this.props.events) {
            this.localRef.current.addEventListener(key, this.props.events[key])
        }
    };

    render() {
        if (!this.props.tag) {
            throw Error("Prop <tag> missing")
        }
        const s = this.props.tag.split("_");
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }
        let dv = "";
        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        if (s !== undefined && s.length === 1) {
            dv = state[s[0]]
        }
        return (

            <label className={this.props.className} style={this.props.type === "hidden" ? {display: "none"} : {display: "inline-block"}}>
                {
                    !this.props.noLabel &&
                    <span style={{...(this.props.labelWidth ? {width: this.props.labelWidth + "px"} : {})}}>{this.props.name}</span>
                }
                <input
                    ref={this.props.myref !== undefined ? this.props.myref : (this.props.events !== undefined ? this.localRef : "")}
                    name={this.props.tag}
                    style={{...this.props.style, borderColor: this.props.inputBorderColor, ...(this.props.width ? {width: this.props.width + "px"} : {})}}
                    type={this.props.type === undefined ? "text" : this.props.type}
                    value={dv ?? ""}
                    onChange={this.props.onChange}
                    step={this.props.step}
                    placeholder={this.props.ph}
                    readOnly={this.props.readOnly}
                    onBlur={this.props.onBlur}
                    autoFocus={!!this.props.autoFocus}
                />
                {this.props.children}

            </label>
        )
    }

}

class TextareaInput extends React.Component {
    constructor(props) {
        super(props);
        this.a = 1 + 1;
        this.setState = this.setState.bind(this);
        this.localRef = React.createRef();
    };

    static contextType = FormContext;

    componentDidMount() {
        if (this.props.events === undefined) {
            return
        }
        for (var key in this.props.events) {
            this.localRef.current.addEventListener(key, this.props.events[key])
        }
    };

    render() {
        const s = this.props.tag.split("_");
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        if (s !== undefined && s.length === 2 && state == null) {
            return null;
        }
        let dv = "";
        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        if (s !== undefined && s.length === 1) {
            dv = state[s[0]]
        }
        return (

            <label style={this.props.type === "hidden" ? {display: "none"} : {display: "block"}} className={"textareaLabel"}>
            <span>
                {this.props.name}
            </span><br/>
                <textarea
                    ref={this.props.myref !== undefined ? this.props.myref : (this.props.events !== undefined ? this.localRef : "")}
                    name={this.props.tag}
                    style={{...this.props.style}}
                    type={this.props.type === undefined ? "text" : this.props.type}
                    value={dv}
                    onChange={() => {
                    }}
                    placeholder={this.props.ph}
                />
                {this.props.children}

            </label>
        )
    }

}

const Container = ({ID, name, addName, style, mutable, hideHeading, broad, visible: defaultVisible = true, children, ...props}) => {
    const [visible, setVisible] = useState(defaultVisible);
    return (
        <div id={ID ? ID : (name !== undefined && name !== "" ? makeIdFromHeading(name) : "")}
             className={"reactContainer centercontent " + (broad ? "broad " : "") + props.class}
             style={style}
        >
            {name !== undefined && name !== "" && !hideHeading &&
                <h1
                    onClick={() => mutable ? setVisible(!visible) : null}
                    className={mutable ? "headingToggleAble" : ""}
                >
                    {mutable && <>{visible ? <FaAngleDown/> : <FaAngleRight/>}&nbsp; </>}
                    {name}
                    {addName}
                </h1>
            }
            {visible && children}
            <br/>
        </div>
    )
};


class LightContainer extends React.Component {
    render() {
        const props = this.props;
        return <div>
            {props.name !== undefined && props.name !== "" &&
                <h2 style={{marginTop: "30px"}}>
                    {props.name}
                </h2>
            }
            {props.children}
            <br/>
        </div>

    }

}


class MyTabs extends React.Component {
    constructor(props) {
        super(props);

        const hashLoc = window.location.href.split("#")[1];
        this.tabs = props.tabs.filter(t => t != null);
        const pref = this.props.preferredTabKey;
        let curr;
        if (!!pref) {
            curr = this.tabs.reduce((tmp, curr, i) => curr.key === pref ? i : tmp, 0);
        } else {
            curr = this.tabs.reduce((tmp, curr, i) => curr.name === hashLoc ? i : tmp, 0);
        }
        this.state = {
            viewingTab: curr
        };
    }

    render() {

        let tabCurr = this.tabs[this.state.viewingTab];
        return <>
            {(!this.props.hideHeaderIfOne || this.tabs.length > 1) && this.tabs.map((t, i) => {
                return <button key={"tabbtn" + i} className={"maxbtn"}
                               style={this.state.viewingTab !== i ? {backgroundColor: "#ddd"} : {}}
                               onClick={() => this.setState({viewingTab: i})}>
                    {t.name}
                </button>
            })
            }
            {this.tabs.length > this.state.viewingTab && tabCurr !== null &&
                <div key={"showing tab " + this.state.viewingTab}>{
                    tabCurr.content
                }
                    {!this.props.noRewrite && <Redirect to={"#" + tabCurr.name}/>}
                </div>
            }
        </>
    }
}

const makeIdFromHeading = (name) => name.toLowerCase().replace(/\s/g, "_");

class MyTabsExtended extends React.Component {
    constructor(props) {
        super(props);

        const hashLoc = window.location.href.split("#")[1];

        const tabs = this.props.tabs.filter(t => t != null);
        this.state = {
            viewingTab: tabs.reduce((tmp, curr, i) => curr.name === hashLoc ? i : tmp, 0),
            scrolledIntoView: false,
        };
    }

    componentDidMount() {
        window.setTimeout(() => {
            const [, hash] = window.location.href.split("#");
            const element = document.getElementById(hash);
            if (!hash) {
                this.setState({scrolledIntoView: true})
            }
            if (element) {
                element.scrollIntoView();
                this.setState({scrolledIntoView: true})
            } else {
                //console.log("no such element,")
            }
        }, 600);
    }

    render() {
        const tabs = this.props.tabs.filter(t => t != null);
        return <>
            <section className={"sectionHeader"}>
                {this.props.children}
                <Scrollspy
                    className={"sectionHeaderNav"}
                    items={tabs.filter(t => t !== null).map((t, i) => makeIdFromHeading(t.name))} currentClassName={"current"} offset={-300} onUpdate={(a) => {
                    if (this.state.scrolledIntoView) {
                        const pageurl = window.location.href.split("#")[0] + "#" + (!!a ? a.getAttribute("id") : "");
                        window.history.replaceState(null, document.title, pageurl);
                    }
                }}>
                    {tabs.filter(t => t != null).map((t, i) => {
                        return <a key={"tabbtn" + i} href={"#" + makeIdFromHeading(t.name)} className={"maxbtn"}
                                  onClick={() => {
                                      this.setState({viewingTab: i})
                                  }}>
                            {t.name}
                        </a>
                    })

                    }
                </Scrollspy>
            </section>
            <section className={"sectionHeaderBackground"}/>

            <section className={"sectionContent"}>
                {
                    tabs
                        .filter(t => t != null)
                        .map((t, i) => {
                            return <Container name={t.name} hideHeading={t.hideHeading}>
                                {t.content}
                            </Container>
                        })
                }
            </section>
        </>;
    }
}

const quillToolbarOptions = [
    ["video", "link", "image"],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    //['blockquote', 'code-block'],

    //[{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    //[{'direction': 'rtl'}],                         // text direction

    //[{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'font': []}],
    [{'align': []}],
    ['clean'],
    ['table'],

];

//const EditorInput = TextareaInput;

class EditorInput extends React.Component {
    static contextType = FormContext;

    editorChange = (content) => {
        const s = this.props.tag.split("_");
        this.context.setState({
            [s[0]]: Object.assign({}, this.context.state[s[0]], {
                [s[1]]: content
            })
        })
    };
    handleChange = c => {
        const s = this.props.tag.split("_");
        this.context.setState({[s[0]]: {...this.context.state[s[0]], [s[1]]: c}})
    };

    render() {
        const s = this.props.tag.split("_");
        return <div style={{marginBottom: "20px"}}>
            <div style={{marginBottom: "10px"}}>{this.props.name}</div>
            {/*<ReactQuill
                modules={this.modules}
                onChange={this.handleChange}
                value={this.context.state[s[0]][s[1]] || ""}
            />*/}

            <Editor tinymceScriptSrc={"/static/tinymce/tinymce.js"} value={this.context.state[s[0]][s[1]] || ""} onEditorChange={this.handleChange} init={{

                language: "de",
                language_url: '/tinymce_de.js',
                //plugins: "media mediaembed",
                mediaembed_max_width: 450,
                //height: 600,
                plugins: 'lists table image media link imagetools autoresize fullscreen', // advcode advtable casechange formatpainter pageembed powerpaste
                //toolbar: 'casechange checklist code formatpainter pageembed permanentpen table',
                images_upload_url: "/api/page_blocks/photo/upload",
                //images_upload_credentials: true,
                toolbar_mode: 'floating',
                removed_menuitems: "newdocument",
                toolbar: "undo redo | styleselect | bold italic underline | colorpicker | link image | numlist bullist | fullscreen"
            }}/>

        </div>
    }
}

function CheckboxInput({tag, name, noLabel, labelWidth, change}) {
    const {state} = useContext(FormContext);
    let checked;
    const tagSplit = tag.split("_");
    if (tagSplit.length === 2) {
        checked = state[tagSplit[0]] !== undefined && state[tagSplit[0]][tagSplit[1]] !== undefined && state[tagSplit[0]][tagSplit[1]] ? "checked" : ""
    } else {
        checked = state[tagSplit[0]] !== undefined && state[tagSplit[0]] !== undefined && state[tagSplit[0]] ? "checked" : ""
    }
    return <label className={"switchWrapper"}>
        {noLabel === undefined &&
            <span style={!!labelWidth ? {width: labelWidth.toString() + "px"} : {}}>
                    {name}
            </span>
        }
        <label className={"switch"}>
            <input type="checkbox" name={tag} checked={checked} onChange={change}/>
            <span className="slider"/>
        </label>
    </label>
}

class DateInput extends React.Component {
    static contextType = FormContext;
    handleChange = (date) => {
        let dateSave = !!date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 10) : null;
        const s = this.props.tag.split("_");
        let newData;
        this.context.updateState(s[0], s[1], dateSave)
        /*if (s.length === 1) {
            newData = dateSave;
            this.context.setState({[this.props.tag]: dateSave})
        } else {
            newData = Object.assign(
                {},
                this.context.state[s[0]],
                {[s[1]]: dateSave}
            );
            this.context.setState({[s[0]]: newData});
        }*/

        if (!!this.props.afterUpdate) {
            this.props.afterUpdate(s[0], s[s.length - 1], newData)
        }

    };

    render() {
        let d = null;
        const s = this.props.tag.split("_");

        if (s.length === 1 && !!this.context.state[this.props.tag]) {
            const val = this.context.state[this.props.tag].split("-");
            d = new Date(val[0], val[1] - 1, val[2]);
        } else if (s.length === 2 && this.context.state[s[0]] && !!this.context.state[s[0]][s[1]]) {
            const val = this.context.state[s[0]][s[1]].split("T")[0].split("-");
            d = new Date(val[0], val[1] - 1, val[2]);
        } else {
            //d = new Date(Math.ceil(d.getTime() / (15 * 60 * 1000), 0) * 15000 * 60);
            if (false && this.props.midNight) {

                d.setHours(0);
                d.setMinutes(0);
                d.setSeconds(0)
            }
        }
        const Dp = <DatePicker selected={isNaN(d) ? null : d}
                               locale={"de"}
                               dateFormat={"dd.MM.yyyy"}
                               placeholderText={"TT.MM.JJJJ"}
                               showYearDropdown
                               peekNextMonth
                               showMonthDropdown
                               maxDate={this.props.maxDate ? new Date(this.props.maxDate) : new Date()}
                               minDate={this.props.minDate && new Date(this.props.minDate)}
                               dropdownMode="select"
                               timeCaption="Datum"
                               portalId="react-datepicker-portal"
                               onChange={this.handleChange}/>
        if (!this.props.name) {
            return <div style={{display: "inline-block"}} className={"DatePickerSmall"}>
                {Dp}
            </div>
        }
        return <>
            <label className={"dateTimeInput"}><span>{this.props.name}</span>
                {Dp}
            </label>
        </>;
    }
}

class DateInputUnix extends React.Component {
    static contextType = FormContext;
    handleChange = (date) => {
        const s = this.props.tag.split("_");
        let newData;
        if (s.length === 1) {
            newData = !!date ? date.getTime() / 1000 : null;
            this.context.setState({[this.props.tag]: newData})
        } else {
            newData = {...this.context.state[s[0]], [s[1]]: !!date ? date.getTime() / 1000 : null};
            this.context.setState({[s[0]]: newData});

        }
        if (!!this.props.afterUpdate) {
            this.props.afterUpdate(s[0], s[s.length - 1], newData)
        }

    };

    render() {
        let d;
        let date;
        const s = this.props.tag.split("_");
        if (this.context.state[s[0]] !== undefined) {
            if (s.length === 2) {
                date = this.context.state[s[0]][s[1]];
            } else {
                date = this.context.state[this.props.tag];
            }
            d = new Date(date * 1000);
            if (isNaN(d.getTime()) || date === 0) {
                d = null//new Date();
            }
        } else {
            d = null//new Date();
        }
        return <>
            <label><span>{this.props.name}</span>
                <DatePicker
                    selected={d}
                    onChange={this.handleChange}
                    locale={"de"}
                    placeholderText={"Zeitpunkt auswählen"}
                    portalId="react-datepicker-portal"
                    dateFormat={"dd.MM.yyyy"}
                />
            </label>
        </>;
    }
}


class DateTimeInput extends React.Component {
    static contextType = FormContext;
    handleChange = (date) => {
        let dateSave = !!date ? date.toISOString() : null;
        const s = this.props.tag.split("_");
        let newData;
        if (s.length === 1) {
            newData = dateSave;
            this.context.setState({[this.props.tag]: dateSave})
        } else {
            newData = Object.assign(
                {},
                this.context.state[s[0]],
                {[s[1]]: dateSave}
            );
            this.context.setState({[s[0]]: newData});
        }

        if (!!this.props.afterUpdate) {
            this.props.afterUpdate(s[0], s[s.length - 1], newData)
        }

    };

    render() {
        let d = null;
        const s = this.props.tag.split("_");
        if (s.length === 2 && this.context.state[this.props.tag] !== undefined && this.context.state[this.props.tag] !== null) {
            d = new Date(this.context.state[this.props.tag]);
        } else if (s.length === 2 && this.context.state[s[0]][s[1]] !== undefined && this.context.state[s[0]][s[1]] !== null) {
            d = new Date(this.context.state[s[0]][s[1]]);
        } else {
            //d = new Date(Math.ceil(d.getTime() / (15 * 60 * 1000), 0) * 15000 * 60);
            if (false && this.props.midNight) {

                d.setHours(0);
                d.setMinutes(0);
                d.setSeconds(0)
            }
        }
        return <>
            <label className={"dateTimeInput"}><span>{this.props.name}</span> <DatePicker selected={d}
                                                                                          locale="de"
                                                                                          showTimeSelect
                                                                                          timeFormat="HH:mm"
                                                                                          timeIntervals={5}
                                                                                          dateFormat="dd.MM.y HH:mm"
                                                                                          timeCaption="Zeit"
                                                                                          maxDate={this.props.maxDate && new Date(this.props.maxDate)}
                                                                                          minDate={this.props.minDate && new Date(this.props.minDate)}
                                                                                          placeholderText={"Zeitpunkt auswählen"}
                                                                                          portalId="react-datepicker-portal"
                                                                                          onChange={this.handleChange}/>
            </label>
        </>;
    }
}

const InputContainer = ({children, block, className, style}) => <span className={"inputContainer " + (className || "")} style={{display: block ? "block" : "inline-block", ...style}}>{children}</span>;

class TagsInput extends React.Component {
    static contextType = FormContext;

    Option = props => {
        return <>
            <UserContext.Consumer>
                {context => context.user?.role === 100 &&
                    <span style={{float: "right", cursor: "pointer", marginRight: "20px", marginTop: "10px"}} onClick={(e) => {
                        //console.log("newcourses", this.state.tags.filter(t => t.value !== props.data.value))
                        this.setState({tags: this.state.tags.filter(t => t.value !== props.data.value)});
                        this.apiGet(`/tags/hide/${this.props.entity}/${props.data.value}`)
                    }}>
                        <FaTrashAlt/>
                    </span>
                }
            </UserContext.Consumer>
            <components.Option {...props}>
                {props.children}
            </components.Option>
        </>
            ;
    };

    constructor(props) {
        super(props);
        this.tag = this.props.tag.split("_");
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.loadTags()
    }

    loadTags = () => this.state.loadedTags === false ? this.apiGet(`/tags/get_avail/${this.props.entity}`, resp => {
        const allTags = this.state.tags.concat(resp.tags);
        if (!!this.props.pushTags) {
            this.props.pushTags(allTags)
        }
        this.setState({tags: allTags, loadedTags: true},)
    }) : null;

    handleCreateNew = (v) => {
        this.apiPost(`/tags/set/${this.props.entity}/${this.props.ID}`, v, resp => {
            console.log((this.context.state[this.tag[0]] || []), this.context.state);
            this.handleChange(!this.props.multiple ? resp : ((this.context.state[this.tag[0]] || {})[this.tag[1]] || []).concat(resp));
            this.setState({tags: this.state.tags.concat(resp)})
        })
        //}
    };
    handleChange = (v) => {
        this.context.setState({[this.tag[0]]: {...this.context.state[this.tag[0]], [this.tag[1]]: v}});
    };
    state = {inputValue: '', tags: !!this.props.addTags ? this.props.addTags : [], loadedTags: false};

    render() {
        const {error} = this.state;
        return <div style={{...this.props.style, marginBottom: "20px"}}>
            <Status type={"error"} text={error}/>
            {!this.props.noLabel && <label style={{lineHeight: "190%", fontWeight: "bold", display: "inline-block", width: this.props.labelWidth}}>{this.props.name}</label>}
            <UserContext.Consumer>
                {
                    ({club}) =>
                        <CreatableSelect
                            isMulti={!!this.props.multiple}
                            //options={selectValsTest}
                            value={(this.context.state[this.tag[0]] || {})[this.tag[1]]}
                            onChange={this.handleChange}
                            onCreateOption={this.handleCreateNew}
                            components={{Option: this.Option}}
                            noOptionsMessage={() => "fange zu tippen an"}
                            placeholder={"Tippe ..."}
                            style={{maxWidth: 300}}
                            formatCreateLabel={(val) => `Erstelle "${val}"`}
                            options={!this.props.appearingTags ? this.state.tags : this.state.tags.filter(t => this.props.appearingTags.indexOf(t.value) > -1)}
                            classNamePrefix={"MaxSelect"}
                            menuPortalTarget={document.getElementById("reactselectportal")}
                            theme={(theme) => {
                                return {
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary: club?.color,
                                        primary25: club?.color,
                                        neutral20: 'black',
                                        neutral30: club?.color,
                                    },
                                }
                            }}
                        />
                }
            </UserContext.Consumer>
        </div>
    }
}


class EnumInput extends React.Component {
    static contextType = FormContext;


    constructor(props) {
        super(props);
        this.tag = this.props.tag.split("_");
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
    }


    handleChange = (v) => {
        this.context.updateState(this.tag[0], this.tag[1], v)
        //this.context.setState({[this.tag[0]]: {...this.context.state[this.tag[0]], [this.tag[1]]: v}});
    };
    state = {inputValue: '', tags: !!this.props.addTags ? this.props.addTags : [], loadedTags: false};

    render() {
        const {error} = this.state;
        const customStyles = {
            container: (provided, state) => ({
                ...provided,
                ...(this.props.width ? {width: this.props.width + "px!important"} : {}),
            }),
        };
        return <div style={{display: "inline-block"}}>
            <Status type={"error"} text={error}/>
            {!this.props.noLabel && <label style={{lineHeight: "190%", fontWeight: "bold"}}><span>{this.props.name}</span></label>}
            <UserContext.Consumer>
                {
                    ({club}) =>
                        <ReactSelect
                            isMulti={!!this.props.multiple}
                            //options={selectValsTest}
                            value={(this.context.state[this.tag[0]] || {})[this.tag[1]]}
                            onChange={this.handleChange}
                            noOptionsMessage={() => "Keine Option gefunden"}
                            placeholder={"bitte auswählen"}
                            options={this.props.selectives}
                            className={"MaxSelectSmallContainer"}
                            classNamePrefix={"MaxSelectSmall"}
                            menuPortalTarget={document.getElementById("reactselectportal")}
                            isClearable={this.props.isClearable ?? false}
                            styles={customStyles}
                            defaultMenuIsOpen={this.props.defaultOpen}
                            theme={(theme) => {
                                return {
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary: club?.color,
                                        primary25: club?.color,
                                        neutral20: 'black',
                                        neutral30: club?.color,
                                    },
                                }
                            }}
                        />
                }
            </UserContext.Consumer>
        </div>
    }
}


const MyReactTable = ({
                          data, columns, defaultSorted,
                          onTableChange = () => null,
                          pageSizeDef = 25, loading = false, onPageChange = null,
                          manualUpdate = null, resolveData = null,
                          onFilteredChange = null, onSortedChange = null,
                          getTrProps = () => ({}),
                      }) => {
    const tableRef = React.createRef();
    const [pageSize, setPageSize] = useState(pageSizeDef);
    const pushTableData = () => {
        return onTableChange !== undefined && tableRef.current !== null && tableRef.current.getResolvedState().sortedData !== undefined ? onTableChange(tableRef.current.getResolvedState().sortedData) : null
    };
    /*if (manualUpdate) {
        manualUpdate(pushTableData)
    }*/
    const noData = data?.length === 0;
    return <>
        <ReactTable
            previousText={"vorherige Seite"}
            nextText={"nächste Seite"}
            rowsText={"Zeilen"}
            pageText={"Seite"}
            ofText={"von"}
            noDataText={"keine Zeilen vorhanden"}
            loading={loading}
            loadingText={"wird geladen"}
            /*getTdProps={(state, rowInfo, column, instance) => ({
                style: {
                    cursor: 'pointer',
                    overflow: 'visible'
                },
                onClick: () => this.onSelect(rowInfo, column)
            })}*/
            data={data}
            resolveData={resolveData || (data => data)}
            headerStyle={{textAlign: "left"}}
            getTrProps={getTrProps}
            defaultFilterMethod={({id, value: search}, row) => {
                let negativeMatching = false;
                if (search[0] === "!") {
                    negativeMatching = true;
                    search = search.slice(1)
                    if (search === "") {
                        return !row[id]
                    }
                    if (search === "!") {
                        return !!row[id]
                    }
                }
                const returnValue = search => {
                    const searchFloat = parseFloat(search.slice(1));
                    const searchFloatEqual = parseFloat(search.slice(2));
                    if (search[0] === "<") {
                        if (search[1] === "=") {
                            return row[id] !== undefined && (row[id] <= searchFloatEqual || search.length === 2)
                        }
                        return row[id] !== undefined && (row[id] < searchFloat || search.length === 1)
                    }
                    if (search[0] === ">") {
                        if (search[1] === "=") {
                            return row[id] !== undefined && (row[id] >= searchFloatEqual || search.length === 2)
                        }
                        return row[id] !== undefined && (row[id] > searchFloat || search.length === 1)
                    }
                    return (row[id] ?? "").toString().toLowerCase().includes(search.toLowerCase())
                }
                if (search.includes(" UND ")) {
                    return !search.split(" UND ").some(e => !returnValue(e))
                }
                if (search.includes(" ODER ")) {
                    return search.split(" ODER ").some(e => returnValue(e))
                }

                if (negativeMatching) {
                    return !returnValue(search)
                }
                return returnValue(search)
            }}
            style={{width: "100%", backgroundColor: "white", display: noData ? "none" : "block"}}
            columns={[
                {
                    columns: columns
                }
            ]
            }
            ref={tableRef}

            onFetchData={pushTableData}
            defaultSorted={defaultSorted !== undefined ? defaultSorted : []}
            //defaultPageSize={pageSize}
            showPagination={data?.length > pageSize}
            pageSize={Math.min(data?.length, pageSize)}
            onPageChange={onPageChange}
            className="-striped -highlight"
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            onSortedChange={(a) => {
                pushTableData();
                onSortedChange && onSortedChange(a);
            }
            }
            onFilteredChange={(a) => {
                pushTableData();
                onFilteredChange && onFilteredChange(a)
            }}/>
        {noData && "nichts vorhanden"}
    </>
};

const MyModal = ({trigger, defaultOpen = false, children, hoverEnabled = false, fullScreen = false, onOpen = null, onClose = null}) => {
    const [open, setOpenRaw] = useState(!!defaultOpen);
    const setOpen = (open) => {
        if (open && onOpen !== null) {
            onOpen()
        }
        setOpenRaw(open)
    };
    const close = () => {
        if (onClose !== null) {
            onClose()
        }
        setOpen(false);
    };
    return <>
        <i
            style={{cursor: "pointer"}}
            onClick={(e) => {
                e.preventDefault();
                setOpen(!open)
            }}
            onMouseOver={() => hoverEnabled && setOpen(!open)}
        >
            {trigger}
        </i>
        <Dialog
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            classes={{root: 'MyDialogRoot', paper: 'MyDialogPaper ' + (fullScreen ? "MyDialogFullScreen" : "")}}
            maxWidth={"lg"}
        >
            <div className={"closeButton"} onClick={close}><FaTimesCircle/></div>
            {typeof children === "function" ? children(close) : children}
        </Dialog>
    </>
};

const MyModalConv = ({trigger, defaultOpen, onOpen, children}) => {
    const [open, setOpen] = useState(!!defaultOpen);
    const close = () => setOpen(false);

    return <Popup trigger={trigger} open={open} onClose={close} onOpen={onOpen} modal>
        {
            close2 => <>
                <div className={"closeButton"} onClick={close2}><FaTimesCircle/></div>
                {typeof children === "function" ? children(close2) : children}
            </>
        }
    </Popup>
};
const MaxBtn = ({
                    style = {}, children, className = "", onClick = () => {
    }, noFormSubmit = false
                }) => <button style={style} type={noFormSubmit ? "button" : "submit"} onClick={onClick} className={"maxbtn " + (className || "")}>{children}</button>;
const MiniBtn = ({style, children, className, onClick, noFormSubmit = false}) => <MaxBtn style={style} noFormSubmit={noFormSubmit} onClick={onClick} className={"mini " + (className || "")}>{children}</MaxBtn>;
const Loader = ({loading}) => loading ? <img alt={"loader"} width={20} height={20} src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> : null;

function ButtonSelect({selectives, tag}) {
    const context = useContext(FormContext);
    const s = tag.split("_");
    const stateValue = (context.state[s[0]] || {})[s[1]];

    return selectives.map(({label, value}) => <>
        <MiniBtn
            style={stateValue === value ? {backgroundColor: "#ddd"} : {}}
            onClick={() => context.updateState(s[0], s[1], value)}>
            {label}
        </MiniBtn> </>)

}


function MyCopier({children}) {
    const [copied, setCopied] = useState(false);
    return <CopyToClipboard text={children} onCopy={() => {
        setCopied(true);
        window.setTimeout(() => setCopied(false), 5000);
    }}>
        {copied ? <>&nbsp;<FaCheck/></> : <b style={{cursor: "pointer"}}> <FaCopy/></b>}
    </CopyToClipboard>
}


export {
    TextfieldInput,
    TextareaInput,
    SelectfieldInput,
    Container,
    LightContainer,
    FileUpload,
    DateInput,
    DateTimeInput,
    MyTabs,
    MyTabsExtended,
    MaxBtn,
    MiniBtn,
    Loader,
    EditorInput,
    CheckboxInput,
    InputContainer,
    TagsInput,
    EnumInput,
    MyReactTable,
    MyModal,
    MyModalConv,
    DateInputUnix,
    ButtonSelect,
    MyCopier,
};
