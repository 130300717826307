import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import React, {useContext, useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import {ExamTypeMap, QuestionTopicLabelMap, QuestionTopicMap} from "../core/enums";
import {dateFormatTime} from "../core/dateFuncs";
import {FaCheckCircle} from "react-icons/all";
import {FaTimesCircle} from "react-icons/fa";
import {downloadAsPdf} from "../core/download";
import {UserContext} from "../user/UserContext";
import UserHistory from "../user/userHistory";

export function Examresult({match}) {
    const {examstart_ID} = match.params;
    const {user} = useContext(UserContext);
    const [data, setData] = useState()
    const [{error, loading}, setStatusVar] = useState({})
    const loadExamstart = () => maxiGet(`/exam/examstart/${examstart_ID}/result`, {setStatusVar}).then(data => {
        setData(data)
    })
    useMemo(loadExamstart, [examstart_ID]);

    const {examstart, exam, person, points, points_total, points_per_topic, points_total_per_topic, points_per_topic_detail, remarks_per_topic, others, timeLastModified} = data || {};

    const pointsFormatter = (p) => p
        .toFixed(exam?.type.value === ExamTypeMap.selfservice ? 0 : 2)
        .replace(".", ",")
    //const passedWhole = points_per_topic && !Object.entries(points_per_topic).some(([key, points]) => points < 3) && points >= 25


    return <Container name={"Prüfungsergebnis"} addName={<> zu {exam?.name}</>}>
        <Status text={error} type={"error"}/>
        <Loader loading={loading}/>


        {
            examstart && <>
                <p>
                    Prüfungszeit {dateFormatTime(examstart.timeCreated)} bis {dateFormatTime(timeLastModified)}
                </p>

                <ul style={{paddingLeft: 0}}>

                    {
                        Object.entries(points_per_topic).map(([topic, points]) => {

                            const passed = points >= (exam.type?.value === ExamTypeMap.selfservice ? 3 : 5)

                            return <li style={{listStyle: "none", padding: 8, border: "2px solid " + (passed ? "green" : "red"), borderRadius: "8px", marginBottom: 10}}>
                        <span style={{float: "right"}}>
                            {passed ? <>Teilbereich bestanden <FaCheckCircle style={{marginBottom: -2}}/></> : <>nicht bestanden <FaTimesCircle style={{marginBottom: -2}}/></>}
                        </span>
                                <strong style={{fontSize: 20, paddingBottom: 8, display: "inline-block"}}>{QuestionTopicLabelMap[topic]}: {pointsFormatter(points)} / {pointsFormatter((exam.type.value === ExamTypeMap.synchronous ? 2.5 : 1) * points_total_per_topic[topic])}</strong>
                                <br/>
                                {
                                    exam.type?.value === ExamTypeMap.selfservice && remarks_per_topic[topic]?.length > 0 && <>
                                        <b>Hinweis:</b>
                                        <ul>

                                            {remarks_per_topic[topic].sort().map(a => <li>{a}</li>)}
                                        </ul>
                                    </>
                                }
                                {
                                    points_per_topic_detail[topic] && <>
                                    A: {points_per_topic_detail[topic].a}, B: {points_per_topic_detail[topic].b}, neutral: {points_per_topic_detail[topic].n}
                                    </>
                                }
                            </li>
                        })

                    }
                </ul>
                <h2>
                    Gesamt: {pointsFormatter(points)} Punkte / {pointsFormatter((exam.type.value === ExamTypeMap.synchronous ? 2.5 : 1) * points_total)} Punkten &nbsp;
                    <br/>
                    <br/>
                    <span style={{padding: 5, border: "2px solid " + (examstart.passed ? "green" : "red"), color: (examstart.passed ? "green" : "red"), borderRadius: "8px",}}>
                        {examstart.passed ? <>bestanden <FaCheckCircle style={{marginBottom: -2}}/></> : <>nicht bestanden <FaTimesCircle style={{marginBottom: -2}}/></>}
                    </span>
                </h2>
                {
                    examstart?.passed && examstart.resultingLicence && <h3>
                        Erhaltene Lizenz: {examstart.resultingLicence}
                    </h3>
                }

                <h3>
                    {
                        exam.type?.value === ExamTypeMap.selfservice && (examstart.passed ?
                            <>
                                Wir haben dir soeben eine Bestätigung über das erfolgreiche Bestehen der Prüfung per E-Mail gesendet. Die Bestätigung kann ebenfalls mit folgendem Link heruntergeladen werden:
                                <br/>

                                <MaxBtn onClick={() => maxiGet(`/exam/examstart/${examstart_ID}/certificate`, {setStatusVar}).then(({content}) => downloadAsPdf(content, exam.name + ".pdf"))}>Bestätigung herunterladen</MaxBtn>
                            </> :
                            <>
                                Die Prüfung wurde leider nicht bestanden{points < 25 ? ", weil in Summe weniger als 25 Punkte erreicht wurden" : ", weil ein Teilbereich nicht bestanden wurde"}.
                                Die einzelnen Teilbereiche sind mit Anmerkungen versehen, die zum erfolgreichen Bestehen der Prüfung beitragen können.
                                Ein nächster Antritt ist frühestens in 24 Stunden möglich.
                            </>)
                    }
                    {
                        exam.type?.value === ExamTypeMap.synchronous && examstart.passed && <MaxBtn onClick={() => maxiGet(`/exam/examstart/${examstart_ID}/certificate`, {setStatusVar}).then(({content}) => downloadAsPdf(content, exam.name + ".pdf"))}>Bestätigung herunterladen</MaxBtn>
                    }
                </h3>
                {
                    exam.type?.value === ExamTypeMap.synchronous && !others?.includes(examstart.person_ID) && <MaxLink to={`/prüfungsantritt/${examstart_ID}/0`}>Prüfung von {person?.fullname} öffnen</MaxLink>
                }
                {
                    user?.role >= 38 && <UserHistory userID={examstart_ID} personType={"examstart"}/>
                }

            </>


        }

    </Container>
}