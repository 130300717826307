import React, {useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import {LightContainer, Loader, MaxBtn, MyModal, MyReactTable} from "../core/input_fields";
import {encodeGetParams} from "../core/helpers";
import {FaDownload, FaPlus} from "react-icons/all";
import {dateFormat} from "../core/dateFuncs";
import Consts from "../core/consts";
import Status from "../core/status";
import {Link} from "react-router-dom";
import {downloadAsPdf} from "../core/download";

export function QualificationsList({person_ID, unassociated, titleCell}) {
    const [{loading, error}, setStatusVar] = useState({});
    const [qualifications, setQualifications] = useState([]);
    useMemo(() => maxiGet("/licence/qualifications?" + encodeGetParams({person_ID, unassociated}), {setStatusVar}).then(setQualifications), [person_ID]);

    const download = (ID) => {
        maxiGet("/licence/qualifications/generate_certificate?" + encodeGetParams({qualification_ID: ID}), {setStatusVar}).then(({content, eventName}) => downloadAsPdf(content, eventName + ".pdf"))
    }


    return <LightContainer name={"an der Turnsport-Austria-Akademie erfolgreich absolvierte Aus-/Fortbildungen"}>
        <Loader loading={loading}/>
        <Status type={"error"} text={error}/>
        {
            !(qualifications?.length > 0) &&
            "Leider sind noch keine Aus-/Fortbildungen, die an der Turnsport-Austria-Akademie seit 1. Jänner 2021 absolviert wurden, vorhanden."
        }
        {
            qualifications?.length > 0 && <MyReactTable
                data={qualifications}
                columns={[
                    {
                        Header: "Bezeichnung",
                        accessor: "title",
                        Cell: (titleCell && titleCell(setStatusVar)) || (({value, original}) => original.event_ID ? <>
                            <Link to={"/veranstaltungen/" + original.event_ID}>{value}</Link>
                        </> : value),
                    },
                    {
                        Header: "Zertifikat",
                        accessor: "certificate1",
                        maxWidth: 90,
                        Cell: ({original, value}) => (!!original.certificate1 || original.event_ID) ?
                            <em onClick={() => original.event_ID ? (download(original.ID)) : (window.location.href = Consts.API_PREFIX + "/licence/file/download/" + original.certificate1)}><FaDownload/></em> :
                            ""
                    },
                    /*{
                        Header: "Veranstaltung",
                        accessor: "event_ID",
                        maxWidth: 140,
                        Cell: ({value}) => value > 0 && <Link to={"/veranstaltungen/" + value}>anzeigen <FaArrowCircleRight/></Link>,
                    },*/
                    {
                        Header: "Abschlussdatum",
                        accessor: "timeFinished",
                        Cell: ({value}) => dateFormat(value),
                        maxWidth: 140,
                    },
                    {
                        Header: "verfügbar seit",
                        accessor: "timeCreated",
                        Cell: ({value}) => dateFormat(value),
                        maxWidth: 140,
                    },
                    /*{
                        Header: "Zertifikat",
                        accessor: "certificate1",
                        maxWidth: 140,
                        Cell: ({value, original}) => !!original.certificate1 ? (original.event_ID ? <FaFile  onClick={() => download(original.ID)}/> : <a href={Consts.API_PREFIX + "/licence/file/download/" + original.certificate1}><FaFile/></a>)) : "",
                    },*/
                ]}/>
        }
    </LightContainer>
}


export function AddQualificationToLicence({licence, titleOfQualification, reload}) {
    return <MyModal trigger={<MaxBtn><FaPlus/> an der Turnsport-Austria-Akademie absolvierte {titleOfQualification} zu diesem Antrag hinzufügen</MaxBtn>}>
        <QualificationsList unassociated titleCell={setStatusVar => ({value, original}) => {
            return <em onClick={() => maxiPost("/licence/qualifications/add_to_licence", {licence_ID: licence.ID, qualification_ID: original.ID}, {setStatusVar}).then(reload && reload)}>{!!value ? value : "Keine Bezeichnung vorhanden"} (hinzufügen)</em>
        }}/>
    </MyModal>
}
